class BoxCalculate {

  #data = {
    Size: "",
    NBank: null,
    NPass: null,
    L: null,
    H: null,
    OD: null,
    Cover: {N: null, Nfan: null, Dfan: null},
    Motn: {N: null, L: null},
    PipeDirc: null,
    PipeBend: null,
    Pipes: [],
  }

  /**
   *
   * @param { Calculator.PriceCalculateModel} priceModel
   */
  static from(priceModel) {
    let obj = new BoxCalculate();
    let data = obj.get();
    data.NPass = priceModel.NPass;
    data.NBank = priceModel.NBank;
    data.L = priceModel.L;
    data.H = priceModel.H;
    data.OD = priceModel.MD.OD;
    data.Cover.N = priceModel.Cover.N
    data.Cover.Nfan = priceModel.Cover.Nfan;
    data.Cover.Dfan = priceModel.Cover.Dfan;
    data.Motn.N = priceModel.Motn.N
    data.PipeBend = priceModel.PipeBend
    for (let pipe of priceModel.Pipes) {
      let {OD, Lflar} = pipe
      data.Pipes.push({OD, Lflar})
    }
    return obj;
  }

  setBoxSize(size) {
    this.#data.Size = size;
    return this;
  }

  setPipeDirc(position) {
    this.#data.PipeDirc = position === "V" ?1 : 0;
    return this;
  }

  setMotnL(l) {
    this.#data.Motn.L = this.parseNum(l)
    return this;
  }

  parseNum(num) {
    if (typeof num == "number") return num
    return Number(num)
  }

  get() {
    return this.#data
  }

}

export default BoxCalculate;