class PreviewCalculate {
  /**
   *  数据
   * @type Calculator.PreviewCalculateModel
   */
  #data = {
    L: null,
    Nbank: null,
    Ntube: null,
    Htube: null,
    Wtube: null,
    Dheader: null,
    Hfin: null,
    Tfin: null,
    FPI: null,

    Ncover: null,
    Nfan: null,
    Dfan: null,
    Dirc: null,

    N_m: null,
    L_m: null,
    W_m: null,
    Dist_m: null,

    Mpipe: null,
    PipeBend: null,
    PipeDirc: null,	//管朝向:1垂直，0平行
    PipePos: null,	//管位置：L左侧，R右侧

    Dpipe_i: null,
    Tpipe_i: null,
    Lconn_i: null,
    Rpipe_i: null,
    Dflar_i: null,

    Dpipe_o: null,
    Tpipe_o: null,
    Lconn_o: null,
    Rpipe_o: null,
    Dflar_o: null,
  }

  /**
   *
   * @param { Calculator.PriceCalculateModel} priceModel
   * @param { any} custimInfo
   */
  static from(priceModel, custimInfo) {
    let obj = new PreviewCalculate();
    let data = obj.get();
    data.L = priceModel.L;
	
    data.Nbank = priceModel.NBank;
    data.Ntube = priceModel.tube.n;
    data.Htube = priceModel.tube.h;
	
	console.log( priceModel,"Wtube")
    data.Wtube = priceModel.tube.weight;

    data.Dheader = priceModel.MD.OD;
    data.Hfin = priceModel.fin.h;
    data.Tfin = obj.getCustomValue(custimInfo.fin, "thickness");
    data.FPI = priceModel.fin.fpi;

    data.Ncover = priceModel.Cover.N;
    data.Nfan = priceModel.Cover.Nfan;
    data.Dfan = priceModel.Cover.Dfan;
    data.Dirc = priceModel.Cover.Dirc;


	
	console.log(priceModel,"N_m")
    data.N_m = priceModel.Motn.N;
	

	// 无部件
	if(custimInfo.page3.bracket){
		data.L_m = obj.getCustomValue(custimInfo.page3.bracket, "length");
		data.W_m = obj.getCustomValue(custimInfo.page3.bracket, "width");
		data.Dist_m = obj.getCustomValue(custimInfo.page3.bracket, "distance");
	}
	

    data.Mpipe = priceModel.Mpipe;
    data.PipeBend = priceModel.PipeBend;
    data.PipeDirc = obj.getCustomValue(custimInfo.buttTube.location, "VP") === "V" ? 0 : 1;
    data.PipePos = obj.getCustomValue(custimInfo.buttTube.location, "LR");

    let i = priceModel.Pipes[0];
    let o = priceModel.Pipes[1];

    data.Dpipe_i = i.OD
    data.Tpipe_i = i.T
    data.Lconn_i = i.Lconn
    data.Rpipe_i = i.R
    data.Dflar_i = i.Lflar

    data.Dpipe_o = o.OD
    data.Tpipe_o = o.T
    data.Lconn_o = o.Lconn
    data.Rpipe_o = o.R
    data.Dflar_o = o.Lflar

    return obj;
  }

  getCustomValue(obj, attrName) {
	  console.log(obj,"obj,")
	  console.log(attrName,"attrName")
	  
    return obj.otherObj[attrName].value
  }

  get() {
    return this.#data
  }

}

export default PreviewCalculate;