class PriceCalculate {

  #data = {
    L: null,
    H: null,
    NBank: null,
    NPass: null,
    tube: {},
    fin: {},
    MD: {},
    Mpipe: null,
    PipeBend: null,
    Pipes: [],
    Cover: {},
    Motn: {},
    coat: null,
    inflate: null
  }

  static from(customInfo) {
    let {calcModel, calcRes} = customInfo;
    // console.log(customInfo)
    // console.log(calcModel)
    // console.log(calcRes)

    let {dHeight, dLength, NPass, Ntube} = calcRes;
    let {iBank, dTube} = calcModel
    let {buttTube, coating, isNitrogenFilling, flatTube, fin, collectorTube, page3} = customInfo;
    return new PriceCalculate()
        .setSize(dLength, dHeight)
        .setPass(NPass)
        .setBank(iBank)
        .setPipes(buttTube)
        .setCost(coating)
        .setInflate(isNitrogenFilling)
        .setTube(Ntube, dTube.h, flatTube.otherObj.width.value)
        .setFin(fin)
        .setMD(collectorTube)
        .setMotn(page3)
        .setCover(page3);
  }

  setSize(length, height) {
    this.#data.L = this.parseNum(length);
    this.#data.H = this.parseNum(height);
    return this;
  }

  setBank(bank) {
    this.#data.NBank = this.parseNum(bank);
    return this;
  }

  setPass(pass) {
    this.#data.NPass = this.parseNum(pass);
    return this;
  }

  setCost(coat) {
    this.#data.coat = this.parseNum(coat);
    return this;
  }

  setInflate(inflate) {
    this.#data.inflate = inflate ? 1 : 0;
    return this;
  }

  setPipes(buttTube) {

    let setTube = (tube) => {
      let {otherObj} = tube;
      let {diameter, thickness, lConn, r, flaring, flaringID} = otherObj
      this.#data.Pipes.push({
        OD: this.parseNum(diameter.value),
        T: this.parseNum(thickness.value),
        Lconn: this.parseNum(lConn.value),
        R: this.parseNum(r.value),
        Lflar: this.parseNum(flaring.value ? flaringID.value : 0)
      })
    }

    let {shape, out, material} = buttTube
    let inTube = buttTube.in;

    material = material == "铜" ? 0 : 1;

    this.#data.Mpipe = material;
    this.#data.PipeBend = shape;
    setTube(inTube)
    setTube(out)

    return this;
  }

  setTube(n, h, w) {
    this.#data.tube.n = this.parseNum(n)
    this.#data.tube.h = this.parseNum(h)
    this.#data.tube.weight = this.parseNum(w)
    return this;
  }

  setFin(fin) {
    let {width, height, fpi, weightM} = fin.otherObj
    this.#data.fin.w = this.parseNum(width.value)
    this.#data.fin.h = this.parseNum(height.value)
    this.#data.fin.fpi = this.parseNum(fpi.value)
    this.#data.fin.weight = this.parseNum(weightM.value)
    return this;
  }

  setMD(md) {
    let {manifoldOD, thickness} = md.otherObj
    this.#data.MD.OD = this.parseNum(manifoldOD.value)
    this.#data.MD.delta = this.parseNum(thickness.value)
    return this;
  }

  setCover(page3) {
    let {type, isFlanging} = page3
    let data = 0;
    // 有风罩
    if (type == 0) {
      data = isFlanging ? 2 : 1;
      let {fanNum, apertureDiameter, nut, fanPosition} = page3

      this.#data.Cover.Dirc = this.parseNum(fanPosition)
      this.#data.Cover.Nfan = this.parseNum(fanNum)
      this.#data.Cover.Dfan = this.parseNum(apertureDiameter)
      this.#data.Cover.Dnut = this.parseNum(nut.otherObj.class.value)
    }
    this.#data.Cover.N = this.parseNum(data)
    return this;
  }

  setMotn(page3) {
	  console.log(page3,"page3")
    let {type, bracketLocation, bracket} = page3;
    let data = 0;
    // 有支架
    if (type == 1) {
      data = bracketLocation ? 7 : 1;
      let {price, weight} = bracket.otherObj
      //todo 用户单户计算
      this.#data.Motn.P = this.parseNum(price.value)
      this.#data.Motn.weight = this.parseNum(weight.value)
    }
    this.#data.Motn.N = this.parseNum(data)
    return this;
  }

  parseNum(num) {
    if (typeof num == "number") return num
    return Number(num)
  }

  get() {
    return this.#data
  }
}

export default PriceCalculate;