/**
 * 将键值对转换成查询参数，例如{'name':'admin','age':18}转换成 'name=admin&age=18'
 * @param {Object} data 要转换的键值对
 */
import {ORDER_MODULE_MPF_USER, ORDER_MODULE_MST_USER} from "../../constant";
import Vue from "vue";

/**
 * 把指定url里面的参数转换为map
 * @param {String} url 完整的 url 或 queryString
 */
export function parseParameterMap(url) {
  if (!url) return {};
  let query = splitUrl(url)[1];
  if (!query) return {};
  let parameterList = query.split("&"); //获取所有参数
  let parameterMap = {};
  for (let i in parameterList) { //遍历所有参数
    let parameter = parameterList[i].split("=");
    if (!parameter[0]) continue
    parameterMap[decodeURI(parameter[0])] = decodeURI(parameter[1] || "");
  }
  return parameterMap;
}

/**
 * 分隔 url，把 /pages/my?a=1&b=2#h2 分隔成 ['/pages/my','a=1&b=2','h2']
 */
export function splitUrl(url) {
  if (!url) return ["", "", ""];
  var list = url.replace("#", "?").split("?");
  if (url.indexOf("?") > -1 && url.indexOf("#") > -1) return list;
  if (url.indexOf("?") === -1 && url.indexOf("#") === -1) return [list[0], "", ""];
  if (url.indexOf("?") === -1) return [list[0], "", list[1]];
  if (url.indexOf("#") === -1) return [list[0], list[1], ""];
}


export function parseQueryString(data) {
  if (typeof data === "string") {
    return data;
  }
  let list = []
  for (let key in data) {
    list.push(encodeURI(key) + "=" + encodeURI(data[key]))
  }
  return list.join("&");
}

/**
 * 将url拼接上查询参数，自动将键值对转换成查询参数，例如{'name':'admin','age':18}转换成 'name=admin&age=18'
 * @param {String} url 原始链接
 * @param {Object} data 要转换的键值对
 */
export const addQueryString = (url, data) => {
  url = url || "";
  const queryString = parseQueryString(data);
  if (!queryString) {
    return url;
  }

  if (url.indexOf("?") === -1) {
    url += "?";
  }
  if (!url.endsWith("?") && !url.endsWith("&")) {
    url += "&";
  }
  return url += queryString;
}


/**
 * 生成零件的属性对象
 * @param parts 要格式化的零件
 * @return {*}
 */
export const generatePartOtherObj = (parts) => {
  let other = parts.other;
  if (other) {
    Vue.set(parts, "otherObj", JSON.parse(other))
  }
  return parts;
}

export const getGoodsCustomMode = (type) => {
  return {
    0: ORDER_MODULE_MPF_USER,
    1: ORDER_MODULE_MST_USER
  }[type]
}

export function deepClone(any) {
  return JSON.parse(JSON.stringify(any))
}